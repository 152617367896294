import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import state from '@Common/State'
import { FC } from '@Common/Services'
import { useEffect } from 'react'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const queries = {
  avatar: {
    config: queryConfig.frequent,
    queryFn: ({ queryKey: [key, userReq] }) =>
      userReq ? FC.service('info').get('infoUserData', { query: { userReq } }) : {},
    noInitalPrefetch: true
  },
  fornitoriATL: {
    config: queryConfig.rare,
    queryFn: () => state.auth.state.codSociety ? FC.service('info').get('getAllFornitori', { query: { codSociety: state.auth.state.codSociety } }) : [],
    noInitalPrefetch: true
  },
  blackListSupplier: {
    config: queryConfig.rare,
    queryFn: async () => {
      const { codSociety } = state.auth.state
      if (!codSociety) return []
      const [res] = await FC.service('blackListSuppliers').find({ query: { _id: codSociety } })
      return res?.list || []
    },
    noInitalPrefetch: true
  },
  user: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, userId] }) => {
      if (userId || state.auth.state.userId) { return FC.client.service('users').get(userId || state.auth.state.userId) } else throw new Error('')
    },
    noInitalPrefetch: true
  },
  agency: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, agencyId] }) => {
      if (agencyId || state.auth.state.userAgency) { return FC.client.service('agencies').get(agencyId || state.auth.state.userAgency) } else throw new Error('')
    },
    noInitalPrefetch: true

  },
  agencyStatsByRange: {
    config: queryConfig.frequent,
    queryFn: ({ queryKey: [key, queriesInfo] }) => FC.service('info').get('getAgencyStatsByRange', { query: { queriesInfo } })
  },
  allClients: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('getAllClient'),
    noInitalPrefetch: true
  },
  badges: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('getBadges'),
    noInitalPrefetch: true
  }
}

export const useCQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return useQuery({ queryKey, queryFn, ...config })
}

export const prefetchQuery = (queryKey, extraQueries = []) => {
  const { queryFn, config } = { ...queries, ...extraQueries }[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  window.queryClient.prefetchQuery(queryKey, queryFn, config)
}

export const prefetchQueries = (extraQueries) =>
  Object.entries({ ...queries, ...extraQueries }).forEach(([queryKey, { config, queryFn, defaultKeys = [], noInitalPrefetch }]) => {
    const key = [queryKey, ...defaultKeys]
    !noInitalPrefetch && window.queryClient.prefetchQuery(key, queryFn, config)
  })

export const invalidateQuery = (queryKeys) =>
  (Array.isArray(queryKeys) ? queryKeys : [queryKeys])
    .forEach(queryKey => window.queryClient.invalidateQueries({ queryKey: [queryKey] }))

// INFINITE QUERIES

const infiniteQueries = {
  infiniteClients: {
    firstPageLength: 10,
    pageLength: 100,
    config: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1 }) =>
      // Definisco la dimensione della prima pagina e delle successive, in modo da avere una risposta velocissima per la prima pagina e più lenta per le successive
      FC.client.service('info').get('getAllClient', {
        query:
        {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
          $limit: pageParam === 1 ? firstPageLength : pageLength,
          $sort: { _id: -1 }
        }
      }),
    noInitalPrefetch: false
  }
}
export const useCInfiniteQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config, maxPages = false, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[queryKey[0]] || queryNotFound

  const { data: { pages = [] } = {}, isSuccess, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useInfiniteQuery(
    queryKey,
    queryFn(firstPageLength, pageLength),
    {
      // devo fetchare nuova pagina se sono della lunghezza massima stabilita (firstPageLength o pageLength)
      getNextPageParam: (lastPage, pages) =>
        maxPages && pages?.length === maxPages
          ? null
          : (pages?.length === 1)
              ? (lastPage?.length === firstPageLength) ? pages?.length + 1 : null
              : (lastPage?.length === pageLength) ? pages?.length + 1 : null,
      ...config
    })

  useEffect(() => !isFetching && !isFetchingNextPage && hasNextPage && fetchNextPage(), [isFetchingNextPage, isFetching])

  return { data: pages?.flat(), isSuccess, isFetchingNextPage }
}
