import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import _ from 'lodash'
import noraImg from './Nora.png'
// import { Api, FC } from '../../Services'
// import { cap } from '../../State'
import React, { useEffect, useState } from 'react'
import { FC } from '@Common/Services'
import { Button } from './Button'
import { Dialog } from 'primereact/dialog'
import { Flex } from './Flex'
import { Text } from './Text'
import { Spinner } from '.'
import axios from 'axios'
import { API_BASE_URL } from '../Services/FeathersClient'
import state from '../../Apps/quotes/State'

const debounce = _.debounce((editor, id, onChange) => {
  const data = '<div class="ck-content">' + editor.getData() + '</div>'
  onChange({ [id]: data })
}, 600)

const Editor = ({ id, description, onChange, initialValue, setInitialValue, imageHandler, noDebounce, entityObj = false, disabled, aiFunctions }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loadingAI, setLoadingAI] = useState(false)
  const [newValueAfterAI, setNewValueAfterAI] = useState('')

  function MyCustomUploadAdapterPlugin (editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter({ loader, entityObj })
    }
  }

  const customConfig = {
    toolbar: [
      'fontfamily',
      'fontsize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'link',
      'blockquote',
      ...((imageHandler && entityObj) ? ['uploadImage'] : []),
      'insertTable',
      '|',
      'undo',
      'redo'
    ],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  }

  const modifyText = async (action) => {
    if (!description || description === '<div class="ck-content"></div>') {
      return window.growl.show({ severity: 'error', summary: 'Testo mancante', detail: 'Scrivere un testo' })
    }
    setLoadingAI(true)
    // Use axios to avoid timeout
    setNewValueAfterAI('')
    const { data: { text } } = await axios.post(API_BASE_URL + '/ai', { action, text: description, type: 'modifyText' }, { headers: { Authorization: state.auth.state.jwt } })
    setLoadingAI(false)
    setNewValueAfterAI(text)
  }

  useEffect(() => {
    setInitialValue?.({ ...initialValue, [id]: description })
  }, [modalVisible])

  const CkEditor = (
    <CKEditor
      disabled={disabled}
      onReady={editor => {
        editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
          editor?.ui?.view?.toolbar?.element,
          editor?.ui?.getEditableElement()
        )
      }}
      onError={({ willEditorRestart }) => {
        if (willEditorRestart) {
          this.editor?.ui?.view?.toolbar?.element?.remove()
        }
      }}
      config={customConfig}
      onChange={(event, editor) => {
        if (noDebounce) {
          const data = '<div class="ck-content">' + editor.getData() + '</div>'
          onChange({ [id]: data })
        } else {
          debounce(editor, id, onChange)
        }
      }}
      editor={DecoupledEditor}
      data={initialValue}
    />
  )

  return (
    <div style={{ backgroundColor: 'white', width: '100%', borderRadius: 20, color: 'black', position: 'relative' }}>
      {!!aiFunctions &&
        <Button
          icon='fa-stars' label='Funzioni AI' style={{ position: 'absolute', right: 5, top: 5 }} onClick={() => {
            setNewValueAfterAI('')
            setModalVisible(true)
          }}
        />}
      <Dialog
        modal style={{ width: '98%', height: '98%', borderWidth: 0, borderRadius: 20 }}
        visible={modalVisible}
        closable
        showHeader={false}
        onHide={() => setModalVisible(false)}
      >
        <Flex fw as js style={{ backgroundColor: 'rgba(255,255,255,0.75)', padding: 20, borderRadius: 20 }}>
          <Flex fw ae>
            <Button icon='close' round onClick={() => setModalVisible(false)} />
          </Flex>
          <Flex fw>
            {loadingAI
              ? (
                <Flex row>
                  <>
                    <Text value='Elaborazione in corso' style={{ fontWeight: 'bold', marginTop: 10, width: 150 }} />
                    <Spinner size={20} />
                  </>
                </Flex>)
              : (
                <Flex fw row>
                  <Flex style={{ width: '25%' }}>
                    <img src={noraImg} alt='Nora' style={{ width: '100%', height: 'auto', resizeMode: 'contain', borderTopRightRadius: 20, borderBottomRightRadius: 20 }} />
                  </Flex>
                  <Flex style={{ width: '25%' }}>
                    <Button label='Migliora' icon='fa-stars' onClick={() => modifyText('improve')} disabled={loadingAI} />
                    <Text value='Rendi il testo più efficace e accattivante' style={{ marginTop: 10 }} />
                  </Flex>
                  <Flex style={{ width: '25%' }}>
                    <Button label='Riassumi' icon='fa-list' onClick={() => modifyText('summarize')} style={{ marginLeft: 10, marginRight: 10 }} disabled={loadingAI} />
                    <Text value='Rendi il testo più sintetico e conciso' style={{ marginTop: 10 }} />
                  </Flex>
                  <Flex style={{ width: '25%' }}>
                    <Button label='Correggi' icon='fa-badge-check' onClick={() => modifyText('correct')} disabled={loadingAI} />
                    <Text value='Correggi eventuali errori presenti nel testo' style={{ marginTop: 10 }} />
                  </Flex>
                </Flex>)}
            <Flex fw as row js style={{ marginTop: 10 }}>
              <Flex js style={{ width: 'calc(50% - 10px)', marginRight: 10 }}>
                <Text value='Testo iniziale' style={{ marginBottom: 20 }} size={22} bold />
                <div style={{ backgroundColor: 'white', width: '100%', borderRadius: 20, color: 'black', position: 'relative' }}>
                  {CkEditor}
                </div>
              </Flex>
              <Flex js style={{ width: 'calc(50% - 10px)', marginLeft: 10 }}>
                {newValueAfterAI &&
                  <>
                    <Text value='Testo modificato' style={{ marginBottom: 21 }} size={22} bold />
                    <div style={{ borderLeft: '1px solid black', backgroundColor: 'white', width: '100%', borderRadius: 20, color: 'black', position: 'relative' }}>
                      <CKEditor
                        disabled editor={DecoupledEditor} data={newValueAfterAI}
                      />
                    </div>
                  </>}
              </Flex>
            </Flex>
            <Flex fw row style={{ marginTop: 10 }}>
              <Button label='Annulla' icon='close' onClick={() => setModalVisible(false)} style={{ marginRight: 5, width: 150 }} />
              <Button
                label='Conferma' icon='check' onClick={() => {
                  onChange({ [id]: newValueAfterAI })
                  setInitialValue({ ...initialValue, [id]: newValueAfterAI })
                  setModalVisible(false)
                }}
                disabled={!newValueAfterAI}
                style={{ marginLeft: 5, width: 150 }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Dialog>
      {CkEditor}
    </div>
  )
}

class MyUploadAdapter {
  constructor (props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props.loader
    this.entityObj = props.entityObj
    this.errorImage = { default: 'https://developers.google.com/maps/documentation/maps-static/images/error-image-generic.png?hl=it' }
    // URL where to send files.
  }

  // Starts the upload process.
  async upload () {
    try {
      const base64 = this.loader._reader._data
      return this.loader.file.then(({ name, type }) => this.uploadFile({ name, type }, base64))
    } catch (e) {
      console.log('ERR => ', e)
      return this.errorImage
    }
  }

  // Aborts the upload process.
  abort () {

  }

  async uploadFile (file, base64) {
    // const cap = { entity: '', entityId: '' }
    // const { entity, entityId } = cap // cap.entity.state
    try {
      const uri = base64
      const res = await FC.client
        .service('upload')
        .create({ uri }, {
          query: this.entityObj
        })
      const data = {
        file: file.name,
        format: file.type,
        from: 'BACKEND_APPTOUR'
      }
      const documentsRes = await FC.service('documents').patch(res.documentId, data)

      return { default: documentsRes.uri }
    } catch (e) {
      console.log('ERRORE => ', e)
      window.growl.show({
        severity: 'error',
        summary: 'Errore immagine inserita',
        detail: e.message === 'big' ? 'File troppo grande ' : 'Errore caricamento immagine'
      })
    }
    return this.errorImage
  }
}

export { Editor }
